<template>
  <div class="patent-detail-container">
    <div class="head">
      <div class="top">
        <span class="type">[专利]</span>
        <span class="title">{{ detailsData.patentName }}</span>
      </div>
      <IconButton1
        v-if="detailsData.id"
        :every="detailsData"
        :curcer="detailsData.id"
      ></IconButton1>
    </div>
    <ul class="content">
      <li v-if="detailsData.artSummary" class="msg">
        <div>摘要：</div>
        <span>
          {{ detailsData.artSummary }}
        </span>
      </li>
      <li v-if="detailsData.patentType" class="msg">
        <div>专利类型：</div>
        <span>{{ detailsData.patentType }}</span>
      </li>
      <li v-if="detailsData.patentNumber" class="msg">
        <div>申请/专利号：</div>
        <span>{{ detailsData.patentNumber }}</span>
      </li>
      <li v-if="detailsData.applyTime" class="msg">
        <div>申请日期：</div>
        <span>{{ detailsData.applyTime | dayFormate }}</span>
      </li>
      <li v-if="detailsData.publicNumber" class="msg">
        <div>公开/公告号：</div>
        <span>{{ detailsData.publicNumber }}</span>
      </li>
      <li v-if="detailsData.publishTime" class="msg">
        <div>公开/公告日：</div>
        <span>{{ detailsData.publishTime | dayFormate }}</span>
      </li>
      <li v-if="detailsData.applyUser" class="msg">
        <div>申请/专利权人：</div>
        <span>{{ detailsData.applyUser }}</span>
      </li>
      <li v-if="detailsData.patentAddress" class="msg">
        <div>主申请人地址：</div>
        <span>{{ detailsData.patentAddress }}</span>
      </li>
      <li v-if="detailsData.inventor" class="msg">
        <div>发明/设计人：</div>
        <span
          class="inventor"
          v-for="(v, i) in detailsData.inventor.split(',')"
          :key="i"
          @click="goAutherDetail(i)"
          >{{ v }},
        </span>
      </li>
      <li v-if="detailsData.masterClassifyNumber" class="msg">
        <div>主分类号：</div>
        <span>{{ detailsData.masterClassifyNumber }}</span>
      </li>
      <li v-if="detailsData.classifyNumber" class="msg">
        <div>分类号：</div>
        <span>{{ detailsData.classifyNumber }}</span>
      </li>
      <li v-if="detailsData.provinceNumber" class="msg">
        <div>国别省市代码：</div>
        <span>{{ detailsData.provinceNumber }}</span>
      </li>
      <li v-if="detailsData.sizeNumber" class="msg">
        <div>页码：</div>
        <span>{{ detailsData.sizeNumber }}</span>
      </li>
      <li v-if="detailsData.proxyMechanism" class="msg">
        <div>专利代理机构：</div>
        <span>{{ detailsData.proxyMechanism }}</span>
      </li>
      <li v-if="detailsData.proxyUser" class="msg">
        <div>代理人：</div>
        <span>{{ detailsData.proxyUser }}</span>
      </li>
      <li v-if="detailsData.priority" class="msg">
        <div>优先权：</div>
        <span>{{ detailsData.priority }}</span>
      </li>
      <li v-if="detailsData.principalRight" class="msg">
        <div>主权项：</div>
        <span>{{ detailsData.principalRight }}</span>
      </li>
      <li v-if="detailsData.lagaList" class="msg">
        <div style="margin-bottom: 15px">法律状态：</div>
        <Table
          border
          :columns="[
            {
              title: '法律状态公告日',
              key: 'lagalPublishTime',
            },
            {
              title: '法律状态',
              key: 'lagalStatus',
            },
            {
              title: '法律状态信息',
              key: 'lagalMessage',
            },
          ]"
          :data="data1"
        ></Table>
      </li>
    </ul>
    <Other :otherList="otherList"></Other>
  </div>
</template>

<script>
import Other from "../components/Other.vue";
import { mapState } from "vuex";
import IconButton1 from "@/components/pager/IconButton1.vue";
export default {
  components: {
    IconButton1,
    Other,
  },
  data() {
    return {
      detailsData: {},
      otherList: [],
    };
  },
  computed: {
    ...mapState(["table"]),
    data1() {
      return this.detailsData.lagaList.map((item) => {
        return {
          lagalMessage: item.lagalMessage,
          lagalStatus: item.lagalStatus,
          lagalPublishTime: item.lagalPublishTime.split(" ")[0],
        };
      });
    },
  },
  created() {
    this.getDetails({
      articleType: this.$route.query.articleType,
      articleId: this.$route.query.articleId,
      projectId: 3,
    });
    this.getRead();
  },
  methods: {
    async getRead() {
      await this.$apis.autherServe.readCount({
        articleId: this.$route.query.articleId,
        articleType: this.$route.query.articleType,
      });
    },
    goAutherDetail(i) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id: this.detailsData.inventorId.split(",")[i],
        },
      });
      window.open(url.href, "_blank");
    },
    async getDetails(data) {
      const resp = await this.$apis.detailServe.getDetails(data);
      this.detailsData = resp.data.data;
      document.title = this.detailsData.patentName + "-百家在线";
      this.getWenXian({
        keyword: this.detailsData.keyword,
        title: this.detailsData.patentName,
        articleType: this.$route.query.articleType,
        pageNo: 1,
        pageSize: 5,
      });
    },
    async getWenXian(data) {
      const resp = await this.$apis.detailServe.getWenXian(data);
            if(resp.resultCode == 200){
        this.otherList = resp.data.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patent-detail-container {
  .head {
    padding-bottom: 26px;
    border-bottom: 1px dashed #d6d6d6;
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .content {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 40px;
    .msg {
      margin-top: 39px;
      div {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      span {
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
      }
      .inventor {
        color: #00a4ff;
        cursor: pointer;
      }
    }
  }
}
</style>
